import React from 'react'
import styles from './Adv.module.scss'
import Optimize from './blocks/optimize/Optimize'
// import Container from '../../UI/container/Container'
import OurAdv from './blocks/ourAdv/OurAdv'
import Res from './blocks/res/Res'
import Price from './blocks/price/Price'
import Recom from './blocks/recom/Recom'
import Problems from './blocks/problems/Problems'
import Reviews from './blocks/reviews/Reviews'
import Form from './blocks/form/Form'
import Contacts from './blocks/contacts/Contacts'
import Audit from './blocks/audit/Audit'

const Adv = () => {
  return (
    <div className={styles.adv}>
      {/* <Container> */}
      <Optimize />
      <Problems />
      <OurAdv />
      <Res />
      <Audit />
      <Price />
      <Recom />
      {/* <Problems /> */}
      <Reviews />
      <Form />
      <Contacts />
      {/* </Container> */}
    </div>
  )
}

export default Adv