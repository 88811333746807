import React from 'react'
import styles from './Reviews.module.scss'
import Container from '../../../../UI/container/Container'
// import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'
import 'swiper/scss/pagination';
import stars from '../../../../assets/img/adv/stars.svg'
import deg from '../../../../assets/img/adv/deg.svg'
import img1 from '../../../../assets/img/adv/1.svg'
import img2 from '../../../../assets/img/adv/2.svg'
import img3 from '../../../../assets/img/adv/3.svg'
import img4 from '../../../../assets/img/adv/4.jpg'

const Reviews = () => {

  // const pagination = {
  //   clickable: true,
  //   renderBullet: function (index: number, className: string) {
  //     console.log(className);

  //     if (className === 'swiper-pagination-bullet') {
  //       return `<span class="${styles.swiperPaginationBullet}">${(index + 1)}</span>`
  //       // return `<span style='margin-bottom: -30px; width: 20px; height: 20px; text-align: center; line-height: 20px; font-size: 24px; color: #c1c1c1; opacity: 1; background: rgba(0, 0, 0, 0.2);' class="${className}">${(index + 1)}</span>`
  //     } else {
  //       return `<span class="${styles.swiperPaginationBulletActive}">${(index + 1)}</span>`
  //     }
  //   },
  // };

  return (
    <div className={styles.reviews}>
      <Container className={styles.reviews2}>
        <div className={styles.title}>
          Отзывы <br /> <span>Клиентов</span>
        </div>
        <div className={styles.desktop}>
          <Swiper
            spaceBetween={50}
            slidesPerView={2.8}
            style={{ cursor: 'pointer' }}
          // modules={[Pagination]}
          // pagination={pagination}
          >
            <SwiperSlide>
              <div className={styles.review}>
                <div className={styles.top}>
                  <img className={styles.ava} src={img1} alt="ava" />
                  <div className={styles.nameContainer}>
                    <p className={styles.name}>Марина К.,</p>
                    <p className={styles.job}>финансовый директор:</p>
                  </div>
                  <img className={styles.stars} src={stars} alt="stars" />
                </div>
                <div className={styles.revText}>
                  "Greenavi значительно упростила нашу работу с криптовалютами. Благодаря их инструментам, мы смогли оптимизировать свои налоговые обязательства и уменьшить расходы. Отличная поддержка и прозрачность во всех процессах!"
                </div>
                <img className={styles.deg} src={deg} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.review}>
                <div className={styles.top}>
                  <img className={styles.ava} src={img2} alt="ava" />
                  <div className={styles.nameContainer}>
                    <p className={styles.name}>Андрей Л.,</p>
                    <p className={styles.job}>владелец малого бизнеса:</p>
                  </div>
                  <img className={styles.stars} src={stars} alt="stars" />
                </div>
                <div className={styles.revText}>
                  "Работа с Greenavi была настоящим открытием. Их команда помогла нам быстро настроить обмен криптовалют и внедрить новые финансовые инструменты, такие как фьючерсы. Теперь наш бизнес работает намного эффективнее."
                </div>
                <img className={styles.deg} src={deg} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.review}>
                <div className={styles.top}>
                  <img className={styles.ava} src={img3} alt="ava" />
                  <div className={styles.nameContainer}>
                    <p className={styles.name}>Олег П., </p>
                    <p className={styles.job}>частный инвестор:</p>
                  </div>
                  <img className={styles.stars} src={stars} alt="stars" />
                </div>
                <div className={styles.revText}>
                  "Платформа Greenavi предлагает отличный функционал для криптоинвесторов. Я использую их инструменты для маржинальной торговли, и результаты превзошли мои ожидания. Отдельная благодарность за удобный интерфейс и поддержку"
                </div>
                <img className={styles.deg} src={deg} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.review}>
                <div className={styles.top}>
                  <img className={styles.ava} src={img4} alt="ava" />
                  <div className={styles.nameContainer}>
                    <p className={styles.name}>Дарья В.,</p>
                    <p className={styles.job}>владелец малого бизнеса:</p>
                  </div>
                  <img className={styles.stars} src={stars} alt="stars" />
                </div>
                <div className={styles.revText}>
                  "Оптимизация НДС требует тщательного анализа и учета всех нюансов законодательства. Команда Greenavi отлично справилась с этой задачей, помогли мне с оптимизацией затрат и повышению эффективности бизнеса, так же получила некоторые рекомендации у специалистов в области налогообложения."
                </div>
                <img className={styles.deg} src={deg} alt="" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className={styles.mobile}>
          <Swiper
            spaceBetween={25}
            slidesPerView={1.5}
            style={{ cursor: 'pointer' }}
          // centeredSlides={true}
          // modules={[Pagination]}
          // pagination={pagination}
          >
            <SwiperSlide>
              <div className={styles.review}>
                <div className={styles.top}>
                  <img className={styles.ava} src={img1} alt="ava" />
                  <div className={styles.nameContainer}>
                    <p className={styles.name}>Марина К.,</p>
                    <p className={styles.job}>финансовый директор:</p>
                  </div>
                  <img className={styles.stars} src={stars} alt="stars" />
                </div>
                <div className={styles.revText}>
                  "Greenavi значительно упростила нашу работу с криптовалютами. Благодаря их инструментам, мы смогли оптимизировать свои налоговые обязательства и уменьшить расходы. Отличная поддержка и прозрачность во всех процессах!"
                </div>
                <img className={styles.deg} src={deg} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.review}>
                <div className={styles.top}>
                  <img className={styles.ava} src={img2} alt="ava" />
                  <div className={styles.nameContainer}>
                    <p className={styles.name}>Андрей Л.,</p>
                    <p className={styles.job}>владелец малого бизнеса:</p>
                  </div>
                  <img className={styles.stars} src={stars} alt="stars" />
                </div>
                <div className={styles.revText}>
                  "Работа с Greenavi была настоящим открытием. Их команда помогла нам быстро настроить обмен криптовалют и внедрить новые финансовые инструменты, такие как фьючерсы. Теперь наш бизнес работает намного эффективнее."
                </div>
                <img className={styles.deg} src={deg} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.review}>
                <div className={styles.top}>
                  <img className={styles.ava} src={img3} alt="ava" />
                  <div className={styles.nameContainer}>
                    <p className={styles.name}>Олег П., </p>
                    <p className={styles.job}>частный инвестор:</p>
                  </div>
                  <img className={styles.stars} src={stars} alt="stars" />
                </div>
                <div className={styles.revText}>
                  "Платформа Greenavi предлагает отличный функционал для криптоинвесторов. Я использую их инструменты для маржинальной торговли, и результаты превзошли мои ожидания. Отдельная благодарность за удобный интерфейс и поддержку"
                </div>
                <img className={styles.deg} src={deg} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.review}>
                <div className={styles.top}>
                  <img className={styles.ava} src={img4} alt="ava" />
                  <div className={styles.nameContainer}>
                    <p className={styles.name}>Дарья В.,</p>
                    <p className={styles.job}>владелец малого бизнеса:</p>
                  </div>
                  <img className={styles.stars} src={stars} alt="stars" />
                </div>
                <div className={styles.revText}>
                  "Оптимизация НДС требует тщательного анализа и учета всех нюансов законодательства. Команда Greenavi отлично справилась с этой задачей, помогли мне с оптимизацией затрат и повышению эффективности бизнеса, так же получила некоторые рекомендации у специалистов в области налогообложения."
                </div>
                <img className={styles.deg} src={deg} alt="" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className={styles.extraSmall}>
          <Swiper
            spaceBetween={25}
            slidesPerView={1.1}
            style={{ cursor: 'pointer' }}
          // centeredSlides={true}
          // modules={[Pagination]}
          // pagination={pagination}
          >
            <SwiperSlide>
              <div className={styles.review}>
                <div className={styles.top}>
                  <img className={styles.ava} src={img1} alt="ava" />
                  <div className={styles.nameContainer}>
                    <p className={styles.name}>Марина К.,</p>
                    <p className={styles.job}>финансовый директор:</p>
                  </div>
                  <img className={styles.stars} src={stars} alt="stars" />
                </div>
                <div className={styles.revText}>
                  "Greenavi значительно упростила нашу работу с криптовалютами. Благодаря их инструментам, мы смогли оптимизировать свои налоговые обязательства и уменьшить расходы. Отличная поддержка и прозрачность во всех процессах!"
                </div>
                <img className={styles.deg} src={deg} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.review}>
                <div className={styles.top}>
                  <img className={styles.ava} src={img2} alt="ava" />
                  <div className={styles.nameContainer}>
                    <p className={styles.name}>Андрей Л.,</p>
                    <p className={styles.job}>владелец малого бизнеса:</p>
                  </div>
                  <img className={styles.stars} src={stars} alt="stars" />
                </div>
                <div className={styles.revText}>
                  "Работа с Greenavi была настоящим открытием. Их команда помогла нам быстро настроить обмен криптовалют и внедрить новые финансовые инструменты, такие как фьючерсы. Теперь наш бизнес работает намного эффективнее."
                </div>
                <img className={styles.deg} src={deg} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.review}>
                <div className={styles.top}>
                  <img className={styles.ava} src={img3} alt="ava" />
                  <div className={styles.nameContainer}>
                    <p className={styles.name}>Олег П., </p>
                    <p className={styles.job}>частный инвестор:</p>
                  </div>
                  <img className={styles.stars} src={stars} alt="stars" />
                </div>
                <div className={styles.revText}>
                  "Платформа Greenavi предлагает отличный функционал для криптоинвесторов. Я использую их инструменты для маржинальной торговли, и результаты превзошли мои ожидания. Отдельная благодарность за удобный интерфейс и поддержку"
                </div>
                <img className={styles.deg} src={deg} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.review}>
                <div className={styles.top}>
                  <img className={styles.ava} src={img4} alt="ava" />
                  <div className={styles.nameContainer}>
                    <p className={styles.name}>Дарья В.,</p>
                    <p className={styles.job}>владелец малого бизнеса:</p>
                  </div>
                  <img className={styles.stars} src={stars} alt="stars" />
                </div>
                <div className={styles.revText}>
                  "Оптимизация НДС требует тщательного анализа и учета всех нюансов законодательства. Команда Greenavi отлично справилась с этой задачей, помогли мне с оптимизацией затрат и повышению эффективности бизнеса, так же получила некоторые рекомендации у специалистов в области налогообложения."
                </div>
                <img className={styles.deg} src={deg} alt="" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </Container>
    </div>
  )
}

export default Reviews