import React from 'react'
import styles from './Optimize.module.scss'
import opt from '../../../../assets/img/adv/opt.svg'
import Container from '../../../../UI/container/Container'

const Optimize = () => {
  return (
    <div className={styles.optimize}>
      <Container className={styles.optimize2}>
        <div className={styles.textBlock}>
          <h1>Оптимизация НДС для вашего бизнеса</h1>
          <h3>Снизьте налоговую нагрузку и увеличьте <br /> прибыль с помощью законных методов <br /> оптимизации НДС</h3>
          <h3 style={{ color: "white" }}>До 30 ноября аудит налоговых рисков бесплатно!</h3>
          <button><a href="https://t.me/OPT1mizationbot" target='_blank' rel="noreferrer">Узнать Больше</a></button>
        </div>
        <div className={styles.imgBlock}>
          <img src={opt} alt="" />
        </div>
      </Container>
    </div>
  )
}

export default Optimize