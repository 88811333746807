import React from 'react'
import styles from './Recom.module.scss'
import Container from '../../../../UI/container/Container'
import rec from '../../../../assets/img/adv/rec.svg'
import gree from '../../../../assets/img/adv/gree.svg'

const Recom = () => {
  return (
    <div className={styles.recom}>
      <Container className={styles.recom2}>
        <div className={styles.title}>
          Наши <br /> <span>Предложения</span>
        </div>
        <div className={styles.content}>
          <div className={styles.textBlock}>
            <div className={styles.text2}>
              <img src={gree} alt="" />
              Десятки белых компаний-партнёров с уплаченными налогами, штатом и живыми директорами!
            </div>
            <div className={styles.text2}>
              <img src={gree} alt="" />
              Строгое планирование движения денежных средств
            </div>
            <div className={styles.text2}>
              <img src={gree} alt="" />
              Опыт и профессионализм в области налогообложения и финансового аудита
            </div>
            <div className={styles.text2}>
              <img src={gree} alt="" />
              Индивидуальный подход с учетом особенностей вашего бизнеса
            </div>
            <div className={styles.text2}>
              <img src={gree} alt="" />
              Гарантируем полную конфиденциальность и безопасность ваших данных
            </div>
            <div className={styles.text2}>
              <img src={gree} alt="" />
              Возможность безналичного расчета за услуги
            </div>
          </div>
          <div className={styles.imgBlock}>
            <img src={rec} alt="" />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Recom