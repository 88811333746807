import React, { useCallback, useEffect, useState } from 'react'
import styles from './HeaderMobile.module.scss';
import logo from '../../../assets/img/logo.svg';
import burger from "../../../assets/icons/burger.svg";
import cross from "../../../assets/icons/cross.svg";
import profileIcon from '../../../assets/icons/profile.svg';
import AuthorizationBlock from '../../AuthorizationComponents/AuthorizationBlock';
import { Link, useLocation } from 'react-router-dom';
import { LogOutMethod } from '../../../services/AuthService';
import Container from '../../../UI/container/Container';
import UserInfo from '../../profileComponents/userInfo/UserInfo';
import { UserProfile } from '../../../types/types';
import { ProfileInfo } from '../../../services/UserService';

const HeaderMobile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState(false);
  const [type, setType] = useState('login');
  const [currentPath, setCurrentPath] = useState('')
  const token = localStorage.getItem('access_token') ?? '';

  useEffect(() => {
    setCurrentPath(document.location.pathname)
    token ? setStatus(true) : setStatus(false);
  }, [token])

  const logout = async () => {
    const { data } = await LogOutMethod(token);
    localStorage.removeItem("access_token");
    setStatus(false);
    document.location.href = '/';
  }

  const [profile, setProfile] = useState<UserProfile | null>(null);
  const location = useLocation();

  const checkToken = useCallback(async (token: string) => {
    const { data } = await ProfileInfo(token);
    if (data === 'Token не найден') {
      localStorage.removeItem("access_token");
      (location.pathname !== '/')
        &&
        (location.pathname !== '/help')
        &&
        (location.pathname !== '/privacy')
        &&
        (location.pathname !== '/terms-of-agreement')
        &&
        (location.pathname !== '/rules')
        &&
        (location.pathname !== '/contacts')
        &&
        (location.pathname !== '/news')
        &&
        (location.pathname !== '/p2p/buy')
        &&
        (location.pathname !== '/p2p/sell')
        &&
        (location.pathname !== '/b2b')
        &&
        (location.pathname !== '/adv')
        ? document.location.href = '/' : <></>
    } else {
      setProfile(data)
    }
  }, [location.pathname])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? '';
    checkToken(token);
  }, [checkToken])

  useEffect(() => {
    setIsOpenProfile(false)
  }, [location])

  function jivo_onLoadCallback() {
    //@ts-ignore
    // jivo_api.open()
  }

  const openJivo = () => {
    //@ts-ignore
    jivo_api.open()
  }

  useEffect(() => {
    jivo_onLoadCallback()
  }, [])

  return (
    <>
      <div className={styles.main} id='mob'>
        <Container className={styles.wrap}>
          <div className={styles.header}>
            <Link to={'/'}>
              <img
                src={logo}
                alt=""
                className={styles.logo}
              />
            </Link>
            <div className={styles.burBox}>
              {(token && profile) ?
                <div className={styles.burgerBlock} style={token ? { display: 'flex' } : { display: 'none' }}>
                  <img
                    src={profileIcon}
                    alt=""
                    className={styles.burger}
                    onClick={() => { setIsOpen(false); setIsOpenProfile(!isOpenProfile) }}
                  />
                </div>
                :
                <button className={styles.logBtn} onClick={() => { setOpenModal(true); setIsOpenProfile(false); setIsOpen(false); setType('login') }}>Войти</button>
              }
              <div className={styles.burgerBlock}>
                <img
                  src={isOpen ? cross : burger}
                  alt=""
                  className={styles.burger}
                  onClick={() => { setIsOpenProfile(false); setIsOpen(!isOpen) }}
                />
              </div>
            </div>
          </div>
          <div className={`${styles.content} ${isOpen && styles.active}`}>
            <div className={styles.navigation}>
              <Link to={'/adv'}>
                <div onClick={() => { setCurrentPath('/adv'); setIsOpen(false) }}
                  style={currentPath === '/adv' ? { color: '#0ddcaa36' } : { color: '#ffffff' }}
                  className={styles.navItem}>
                  <p>Главная</p>
                </div>
              </Link>
              {/* NEED */}
              {/* {
                token ?
                  <Link to={'/buy'}>
                    <div
                      onClick={() => { setCurrentPath('/buy'); setIsOpen(false) }}
                      style={currentPath === '/buy' ? { color: '#0ddcaa36' } : { color: '#ffffff' }}
                      className={styles.navItem}>
                      <p>Купить криптовалюту</p>
                    </div>
                  </Link>
                  : <></>
              } */}
              {/* {
                token ? */}
              {/* NEED */}
              {/* <Link to={'/p2p/buy'}>
                <div
                  className={styles.navItem}
                  onClick={() => { setCurrentPath('/p2p/buy'); setIsOpen(false) }}
                  style={currentPath === '/p2p/buy' ? { color: '#0ddcaa36' } : { color: '#ffffff' }}
                >
                  P2P-торговля
                </div>
              </Link> */}
              {/* : <></> */}
              {/* } */}
              {/* {
                token ? */}
              {/* NEED */}
              {/* <Link to={'/b2b'}>
                <div
                  className={styles.navItem}
                  onClick={() => { setCurrentPath('/b2b'); setIsOpen(false) }}
                  style={currentPath === '/b2b' ? { color: '#0ddcaa36' } : { color: '#ffffff' }}
                >
                  <p className={styles.navItemText} >B2B-торговля</p>
                </div>
              </Link> */}
              {/* : <></>
              } */}
              {/* {
                token ?
                  <Link to={'/swap'}>
                    <div
                      className={styles.navItem}
                      onClick={() => { setCurrentPath('/swap'); setIsOpen(false) }}
                      style={currentPath === '/swap' ? { color: '#0ddcaa36' } : { color: '#ffffff' }}
                    >
                      <p className={styles.navItemText} >Обмен</p>
                    </div>
                  </Link>
                  : <></>
              } */}
              {/* NEED */}
              {/* <div className={styles.navItem} >
                <Link to={'/help'}>
                  <p className={styles.navItemText}
                    onClick={() => { setCurrentPath('/help'); setIsOpen(false) }}
                    style={
                      currentPath === '/help'
                        ? { color: '#0ddcaa' }
                        : { color: '#ffffff' }
                    }
                  >Руководство</p>
                </Link>
              </div> */}
              <Link to={'/adv'}>
                <div
                  className={styles.navItem}
                  onClick={() => { setCurrentPath('/adv'); setIsOpen(false) }}
                  style={currentPath === '/adv' ? { color: '#0ddcaa36' } : { color: '#ffffff' }}
                >
                  <p className={styles.navItemText} >Оптимизация</p>
                </div>
              </Link>
              {/* <div className={styles.navItem} >
                <Link to={'/news'}>
                  <p className={styles.navItemText}
                    onClick={() => { setCurrentPath('/news'); setIsOpen(false) }}
                    style={
                      currentPath === '/news'
                        ? { color: '#0ddcaa' }
                        : { color: '#ffffff' }
                    }
                  >Новости</p>
                </Link>
              </div>
              <div className={styles.navItem}>
                <p className={styles.navItemText} onClick={openJivo}>Поддержка</p>
              </div> */}
              {status ? (
                <>
                  {/* <Link to={'/profile'}>
                    <div
                      className={styles.loginBtn}
                      onClick={() => { setIsOpen(false) }}
                    >
                      <p>Профиль</p>
                    </div>
                  </Link> */}
                  <div
                    className={styles.loginBtn}
                    onClick={() => { setIsOpen(false); logout() }}
                  >
                    <p>Выйти</p>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={styles.loginBtn}
                    onClick={() => { setOpenModal(true); setIsOpenProfile(false); setIsOpen(false); setType('login') }}
                  >
                    <p>Войти</p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={`${styles.contentProfile} ${isOpenProfile && styles.activeProfile}`}>
            {profile &&
              <UserInfo
                name={profile.login}
                email={profile.email}
                isVerify={profile.verify_status}
                isEmail={profile.email ? 1 : 0}
                is2Fa={profile.two_factor ? 1 : 0}
                avatar={profile.avatar}
              />
            }
          </div>
        </Container>
      </div>
      <AuthorizationBlock
        type={type}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setStatus={setStatus}
      />
    </>
  )
}

export default HeaderMobile