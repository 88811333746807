import React from 'react'
import styles from './Form.module.scss'
import Container from '../../../../UI/container/Container'
import formImg from '../../../../assets/img/adv/form.svg'

const Form = () => {
  return (
    <div className={styles.form}>
      <Container className={styles.form2}>
        <div className={styles.formBlock}>
          <div className={styles.title}>
            Оставьте <br /> <span>Заявку</span>
          </div>
          <form onClick={(e) => e.preventDefault()}>
            <input type="text" placeholder='Имя' />
            <input type='email' placeholder='Email' />
            <input type="text" placeholder='Укажите свои пожелания' />
            <button>Отправить</button>
          </form>
          <p className={styles.hint}>После отправки наш специалист свяжется с вами в течение 24 часов для предоставления консультации</p>
        </div>
        <div className={styles.imgBlock}>
          <img src={formImg} alt="" />
        </div>
      </Container>
    </div>
  )
}

export default Form