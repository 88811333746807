import React from 'react'
import styles from './OurAdv.module.scss'
import our from '../../../../assets/img/adv/our.svg'
import Container from '../../../../UI/container/Container'

const OurAdv = () => {
  return (
    <div className={styles.our}>
      <Container className={styles.our2}>
        <div className={styles.content}>
          <div className={styles.imgBlock}>
            <img src={our} alt="" />
          </div>
          <div className={styles.textBlock}>
            <div className={styles.title}>
              Наши <br /> <span>преимущества</span>
            </div>
            <p className={styles.text}>Greenavi - это команда профессионалов, которая помогает бизнесу снижать налоговую нагрузку и управлять финансовыми рисками. Мы предлагаем комплексные услуги по налоговой оптимизации, планированию, аудиту и консультированию, чтобы вы могли сосредоточиться на развитии своего бизнеса и избежать ненужных финансовых рисков.</p>

            <p className={styles.text2}>-Уменьшение НДС, корректировка деклараций по любым отчётным периодам</p>
            <p className={styles.text2}>-Законно оптимизируем НДС, согласно НК РФ</p>
            <p className={styles.text2}>-5 различных вариантов оптимизации налогов на выбор</p>
            <p className={styles.text2}>-Команда налоговых специалистов на связи ежедневно</p>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default OurAdv