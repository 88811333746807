import React from 'react'
import styles from './Price.module.scss'
import Container from '../../../../UI/container/Container'
import price from '../../../../assets/img/adv/price.svg'

const Price = () => {
  return (
    <div className={styles.price}>
      <Container className={styles.price2}>
        <div className={styles.content}>
          <div className={styles.imgBlock}>
            <img src={price} alt="" />
          </div>
          <div className={styles.textBlock}>
            <div className={styles.title}>
              Наши <br /> <span>Тарифы</span>
            </div>
            <p className={styles.text}>Чем выше уровень объема, тем ниже цена</p>

            <p className={styles.text}>Специально для Вас:</p>
            <p className={styles.text2}>·      Оплата после обращения</p>
            <p className={styles.text2}>·      Возможность безналичного расчета за услуги</p>
            <button><a href="https://t.me/OPT1mizationbot" target='_blank' rel="noreferrer">Начните сейчас</a></button>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Price