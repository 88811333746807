import React from 'react'
import styles from './Problems.module.scss'
import Container from '../../../../UI/container/Container'

const Problems = () => {
  return (
    <div className={styles.problems}>
      <Container className={styles.problems2}>
        <div className={styles.title}>
          Ваши Вызовы — <br /> <span>Наши Решения</span>
        </div>
        <p className={styles.text}>Часто наши клиенты сталкиваются с типичными проблемами, которые могут существенно замедлить развитие бизнеса:</p>
        <div className={styles.container}>
          <div className={styles.box}>
            <p className={styles.text2}>Переплата налогов: Неоправданно высокие суммы выплат по НДС и другим налогам.</p>
          </div>
          <div className={styles.box}>
            <p className={styles.text2}>Непрозрачность расчетов: Сложности в точном отслеживании финансовых потоков и отчетности.</p>
          </div>
          <div className={styles.box}>
            <p className={styles.text2}>Постоянные штрафы: Риски получения штрафов из-за несоблюдения налоговых норм и ошибок в бухгалтерии.</p>
          </div>
          <div className={styles.box}>
            <p className={styles.text2}>Сложности с планированием: Нехватка контроля за движением денежных средств и  обязательствами.</p>
          </div>
        </div>
        <p className={styles.text3}>Мы знаем, как решить эти проблемы, предоставляя проверенные инструменты и экспертные решения для вашего бизнеса.</p>
      </Container>
    </div>
  )
}

export default Problems