import React from 'react'
import styles from './Res.module.scss'
import Container from '../../../../UI/container/Container'

const Res = () => {
  return (
    <div className={styles.res}>
      <Container className={styles.res2}>
        <div className={styles.title}>
          Быстрое Решение <br /> <span>Для Вашего Бизнеса</span>
        </div>
        <p className={styles.text}>Мы поможем Вам оптимизировать бизнес и снять налоговую нагрузку и увеличить прибыль компании.</p>
        <p className={styles.text}>Законные способы оптимизации НДС:</p>
        <div className={styles.container}>
          <div className={styles.box}>
            <p className={styles.num}>1</p>
            <p className={styles.text2}>Закрыть НДС - Закроем разрыв текущего и предыдущих периодов.</p>
          </div>
          <div className={styles.box}>
            <p className={styles.num}>2</p>
            <p className={styles.text2}>Аудит рисков - Проверим ваши бухгалтерские документы.</p>
          </div>
          <div className={styles.box}>
            <p className={styles.num}>3</p>
            <p className={styles.text2}>Оптимизация налогов - Законное уменьшение налоговых выплат по НДС и НП до 90%.</p>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Res