import React from 'react'
import styles from './Audit.module.scss'
import Container from '../../../../UI/container/Container'

const Audit = () => {
  return (
    <div className={styles.audit}>
      <Container className={styles.audit2}>
        <div className={styles.title}>
          Бесплатный аудит <br /> <span>налоговых рисков</span>
        </div>
        <p className={styles.text}>Получите подробный отчет с рекомендациями по улучшению финансовой стабильности вашего бизнеса. Наши эксперты проведут всесторонний анализ и предложат эффективные решения для минимизации рисков.</p>
        <button><a href="https://t.me/OPT1mizationbot" target='_blank' rel="noreferrer">Получить Аудит</a></button>
      </Container>
    </div>
  )
}

export default Audit