import React from 'react'
import styles from './Contacts.module.scss'
import Container from '../../../../UI/container/Container'
import telegramImg from '../../../../assets/img/adv/telegram.svg'

const Contacts = () => {
  return (
    <div className={styles.contacts}>
      <Container className={styles.contacts2}>
        <div className={styles.title}>
          Наши <br /> <span>Контакты</span>
        </div>
        <div className={styles.container}>
          <img src={telegramImg} alt="" />
          <div className={styles.box}>
            <p className={styles.name}>Telegram</p>
            <p className={styles.ll}><a href="https://t.me/greenaviofficial" target='_blank' rel='noreferrer'>Greenavi</a></p>
          </div>
        </div>
        <div className={styles.container}>
          <img src={telegramImg} alt="" />
          <div className={styles.box}>
            <p className={styles.name}>Email</p>
            <p className={styles.ll}><a href="mailto:support@greenavi.com" target='_blank' rel='noreferrer'>support@greenavi.com</a></p>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Contacts